<template>
  <div class="lg:w-4/5 mx-auto mt-8 px-4 sm:px-6 lg:px-8">
    <div class="card bg-white shadow rounded-lg">
      <div class="card-header px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Terms of Service
        </h3>
      </div>
      <div class="card-body px-4 py-5 sm:p-6">
        <terms-of-service />
      </div>
    </div>
  </div>
</template>

<script setup>
import TermsOfService from '#ui/views/TermsOfService';
</script>

<style scoped>
</style>
